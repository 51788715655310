<template>
  <vx-card title="إضافة طلبية جديدة" title-color="primary">
    <div class="vx-row">
      <div class="vx-col md:w-2/5 w-full">
        <label class="vs-input--label">للمستخدم</label>
        <v-select :options="users" v-model="user" label="name"> </v-select>
        <span
          class="text-danger text-sm"
          v-show="errors.has('order-items.to-the-user')"
          >{{ errors.first("order-items.to-the-user") }}
        </span>
      </div>

      <div v-if="!new_user" class="vx-col md:w-1/5 w-full">
        <label class="vs-input--label">اختر العنوان</label>
        <v-select
          v-model="address_id"
          name="address"
          v-validate="'required'"
          data-vv-as="العنوان"
          label="title"
          :reduce="(item) => item.id"
          :options="user.address"
        ></v-select>
        <span class="text-danger text-sm" v-show="errors.has('address')"
          >{{ errors.first("address") }}
        </span>
      </div>

      <div class="vx-col md:w-2/5 w-full">
        <label class="vs-input--label">حالة الطلب</label>
        <v-select
          v-model="order_status_id"
          name="order_status"
          v-validate="'required'"
          data-vv-as="حالة الطلب"
          label="label"
          :reduce="(item) => item.value"
          :options="orderStatuses"
        ></v-select>
        <span class="text-danger text-sm" v-show="errors.has('order_status')"
          >{{ errors.first("order_status") }}
        </span>
      </div>
    </div>

    <div v-if="new_user" class="vx-row">
            <!-- New User -->
      <div class="vx-col md:w-1/2 w-full">
        <label class="vs-input--label mt-5">أسم المستخدم</label>
        <vs-input
          data-vv-as="أسم المستخدم"
          v-validate="'required'"
          class="w-full"
          name="name"
          v-model="new_user.name"
        />
        <span class="text-danger text-sm" v-show="errors.has('name')"
          >{{ errors.first("name") }}
        </span>
      </div>

      <div class="vx-col md:w-1/2 w-full">
        <label class="vs-input--label mt-5">رقم الهاتف</label>
        <vs-input
          data-vv-as="رقم الهاتف"
          v-validate="'required|numeric'"
          class="w-full"
          name="phone"
          v-model="new_user.phone"
        />
        <span class="text-danger text-sm" v-show="errors.has('phone')"
          >{{ errors.first("phone") }}
        </span>
      </div>

      <div class="vx-col md:w-1/4 w-full">
        <label class="vs-input--label mt-5">المدينة</label>
        <v-select
          v-model="new_user.adress.city_id"
          name="city"
          v-validate="'required'"
          data-vv-as="المدينة"
          label="name"
          :options="cities"
        ></v-select>
        <span class="text-danger text-sm" v-show="errors.has('city')"
          >{{ errors.first("city") }}
        </span>
      </div>
      <div class="vx-col md:w-3/4 w-full">
        <label class="vs-input--label mt-5">الوصف</label>
        <vs-input
          data-vv-as="الوصف"
          v-validate="'required'"
          class="w-full"
          name="description"
          v-model="new_user.adress.description"
        />
        <span class="text-danger text-sm" v-show="errors.has('description')"
          >{{ errors.first("description") }}
        </span>
      </div>
    </div>
    
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <label class="vs-input--label mt-5">وسيلة الدفع</label>
        <v-select
          v-model="payment_method_id"
          name="payment_method"
          v-validate="'required'"
          data-vv-as="وسيلة الدفع"
          label="label"
          :reduce="(item) => item.value"
          :options="paymentMethods"
        ></v-select>
        <span class="text-danger text-sm" v-show="errors.has('payment_method')"
          >{{ errors.first("payment_method") }}
        </span>
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <label class="vs-input--label">استخدام كوبون</label>
        <v-select
          v-model="coupon"
          name="coupon"
          label="code"
          :options="coupons"
        ></v-select>
      </div>
    </div>
    <vs-divider position="left" textClass="text-size" color="primary"
      >التوصيل</vs-divider
    >
    <div class="vx-row">
      <div class="vx-col md:w-2/5 w-full">
        <label class="vs-input--label">مندوب التوصيل</label>
        <v-select
          v-model="delivery_id"
          name="delivery"
          label="name"
          :reduce="(item) => item.id"
          :options="deliveries"
        ></v-select>
      </div>
      <div class="vx-col md:w-2/5 w-full">
        <label class="vs-input--label">تاريخ التوصيل</label>
        <datepicker
          class="w-full"
          format="dd/MM/yyyy"
          v-validate="'required|date_format:dd/MM/yyyy'"
          data-vv-as="تاريخ التوصيل"
          name="delivery_date"
          v-model="delivery_date"
          tabindex="2"
        ></datepicker>
        <span class="text-danger text-sm" v-show="errors.has('delivery_date')"
          >{{ errors.first("delivery_date") }}
        </span>
      </div>
      <div class="vx-col md:w-1/5 w-full">
        <label class="vs-input--label mt-5">سعر التوصيل</label>
        <vs-input
          data-vv-as="سعر التوصيل"
          v-validate="'required|numeric|min:0'"
          type="number"
          class="w-full"
          name="delivery_price"
          v-model="delivery_price"
        />
        <span class="text-danger text-sm" v-show="errors.has('delivery_price')"
          >{{ errors.first("delivery_price") }}
        </span>
      </div>
    </div>
    <vs-divider position="left" textClass="text-size" color="primary"
      >اضافة المنتجات</vs-divider
    >
    <div class="vx-row" v-if="order_item_list.length">
      <OrderItems
        :order_item_list="order_item_list"
        :sub_total="200"
        @deleteItem="deleteItem"
      ></OrderItems>
      <vs-table hoverFlat class="w-1/2 ml-auto mt-4" data="invoiceData">
        <vs-tr>
          <vs-th>المبلغ الإجمالي</vs-th>
          <vs-td>{{ total_amount }} د.ل</vs-td>
        </vs-tr>

        <vs-tr v-if="coupon.id">
          <vs-th> الكوبون المستخدم</vs-th>
          <td>{{ coupon.code }} | {{ coupon.value }} د.ل</td>
        </vs-tr>
        <vs-tr>
          <vs-th>سعر التوصيل</vs-th>
          <td>{{ delivery_price }} د.ل</td>
        </vs-tr>
        <vs-tr>
          <vs-th>وسيلة الدفع</vs-th>
          <td>
            {{ payment_method_id }}
          </td>
        </vs-tr>
      </vs-table>
    </div>
    <div class="vx-row align-items-center">
      <div class="vx-col w-1/5 sm:w-1/5 w-full">
        <label class="vs-input--label"> اختر رقم sku</label>
        <v-select
          name="sku"
          class="w-full"
          label="sku"
          v-model="product"
          @option:selected="clearFields"
          @search="(search,loading) => {productsSKU({params:{search: search}})}"
          :options="products"
          :clearable="false"
          @input="getProductData"
          :filterable="true"
        ></v-select>
      </div>
      <div class="vx-col w-1/5 sm:w-1/5 w-full">
        <label class="vs-input--label">اختر اللون</label>
        <v-select
          class="w-full"
          name="color"
          label="name"
          v-model="color_option"
          :options="quantities"
          :clearable="false"
          :tabindex="4"
          :filterable="true"
        ></v-select>
      </div>
      <div class="vx-col w-1/5 sm:w-1/5 w-full">
        <label class="vs-input--label">اختر الحجم</label>
        <v-select
          class="w-full"
          name="size"
          label="size"
          v-model="size_option"
          :options="color_option.quantities"
          :clearable="false"
          :tabindex="4"
          :filterable="true"
        ></v-select>
      </div>
      <div class="vx-col w-1/5 sm:w-1/5 w-full">
        <label class="vs-input--label">اختر الكمية</label>
        
        <vs-input
          data-vv-as="الكمية"
          v-validate="{
            max_value:  size_option.quantity,
            min_value: 1,
          }"
          type="number"
          class="w-full"
          name="quantity"
          v-model="quantity"
        />
        <span class="text-danger text-sm" v-show="errors.has('quantity')"
          >{{ errors.first("quantity") }}
        </span>
      </div>
      <div class="vx-col w-1/5 sm:w-1/5 w-full flex">
        <vs-button
          class="mt-5"
          color="success"
          type="filled"
          icon-pack="feather"
          icon="icon-plus"
          :disabled="!validateForm"
          @click="addProduct"
        ></vs-button>
        <vs-button
          class="mt-5 ml-2"
          color="warning"
          type="filled"
          icon-pack="feather"
          icon="icon-rotate-ccw"
          @click="resetInputs"
        ></vs-button>
      </div>
    </div>
    <div class="vx-row mt-8">
      <div class="vx-col sm:w-full w-full mt-10 text-right">
        <vs-button
          class="mr-5 text-lg py-4"
          @click="$router.go(-1)"
          color="warning"
          type="border"
          >إلغاء</vs-button
        ><vs-button
          class="mr-5 text-lg px-16 py-4"
          @click="submit"
          :disabled="loading"
          color="primary"
          type="filled"
          >إضافة طلب</vs-button
        >
      </div>
    </div>
  </vx-card>
</template>

<script>
const NewUser = "مستخدم جديد"
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import OrderItems from "./components/order-items.vue";
import { mapActions } from "vuex";
export default {
  components: { vSelect, Datepicker, OrderItems },
  data() {
    return {
      user: "",
      new_user:false,
      address_id: "",
      current_Page: 1,
      prev_page_url: "",
      next_page_url: "",
      itemsPerPage: 5,
      totalItems: 0,
      order_status_id: "",
      delivery_date: null,
      delivery_price: 0,
      coupon: { id: "", code: null },
      payment_method_id: "",
      delivery_id: null,
      discount: 0,
      order_item_list: [],
      product: {
        id: "",
        sku: "",
        title: "",
        price: "",
        currency: {},
      },
      quantity: "",
      color_option: {},
      size_option: { id: "", quantity: '', size: "" },
      quantities: [],
      local_currency: {},
    };
  },
  computed: {
    loading() {
      return this.$store.state.orders.Loading;
    },
    products() {
      return this.$store.state.products.products_sku;
    },
    coupons() {
      return this.$store.state.coupons.coupons;
    },
    deliveries() {
      return this.$store.state.reps.reps;
    },
    users() {
      return [...this.$store.state.users.unpaginated,{name:NewUser}];
    },
    validateForm() {
      return !this.errors.any();
    },
    total_amount() {
      let orders_total_amount = this.order_item_list.reduce(function (s, e) {
        return (
          s +
          (e.new_price ? e.new_price * e.quantity : e.price * e.quantity) *
            e.currency.rate
        );
      }, 0);
      return Math.ceil(orders_total_amount * this.local_currency.reverse_rate);
    },
    cities(){
      return this.$store.state.stores.cities;
    }
  },
  watch:{
    user(user){
      if(user.name == NewUser){
        this.toggleNewUser()
      }else{
        this.new_user = false;
        }
    }
  },
  methods: {
    ...mapActions("orders", ["createNewOrder"]),
    ...mapActions("products", ["productsSKU", "fetchQuantities"]),
    ...mapActions("users", ["fetchUnpaginated"]),
    ...mapActions("coupons", ["fetchCoupons"]),
    ...mapActions("reps", ["fetchReps"]),
    ...mapActions("stores", ["fetchCities"]),

    toggleNewUser(){
        this.new_user = {
          name:"",
          phone:'',
          adress:{
            city_id:'',
            description:'',
            title:'المنزل',
            longitude:'13.158066160976889',
            latitude:'32.830027821365015'
          }
        }
    },
    resetInputs() {
      this.product = {};
      this.quantity = "";
      this.color_option = {};
      this.size_option = { id: "", quantity: '', size: "" };
    },
    getProductData(product) {
      this.fetchQuantities(product.id).then((response) => {
        this.quantities = response.data.data.quantities;
        this.product.price = response.data.data.price;
        this.product.currency = response.data.data.currency;

        //lydusd currency details
        this.local_currency = response.data.data.local_currency;
      });
    },
    clearFields() {
      (this.color_option = {}), (this.size_option = { id: "", quantity: '', size: "" });
    },
    deleteItem(id) {
      this.order_item_list.splice(id, 1);
    },
    addProduct() {
      let new_product = {
        id: this.product.id,
        sku: this.product.sku,
        title: this.product.title,
        price: this.product.price,
        currency: this.product.currency,
        total_quantity: this.size_option.quantity,
        new_price: this.product.new_price,
        color: this.color_option.name,
        size: this.size_option.size,
        quantity_id: this.size_option.id,
        quantity: this.quantity,
      };
      let duplicated = this.order_item_list.find(
        (item) => item.id == new_product.id
      );
      if (duplicated) {
        this.$vs.notify({
          time: 10000,
          title: "خطأ !",
          text: "المنتج موجود مسبقا",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return false;
      }
      this.order_item_list.push(new_product);
      this.product = {};
      this.color_option = {};
      this.size_option = { id: "", quantity: '', size: "" };
      this.quantity = '';
      this.$validator.reset()
    },
    submit() {
      this.$validator.validateAll().then((result) => {
        if (this.order_item_list.length < 1) {
          this.$vs.notify({
            time: 10000,
            title: "خطأ !",
            text: "يرجى إضافة منتج علي الأقل",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
        if (!result) {
          this.$vs.notify({
            time: 10000,
            title: "خطأ !",
            text: "يرجى ملئ كافة الحقول",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        } else {
          let bodyFormdata = new FormData();

          if(this.new_user){

            bodyFormdata.set("user[name]", this.new_user.name);
            bodyFormdata.set("user[phone]", this.new_user.phone);
            bodyFormdata.set("user[password]", '12345678');
            bodyFormdata.set("user[adress][city_id]", this.new_user.adress.city_id.id);
            bodyFormdata.set("user[adress][description]", this.new_user.adress.description);
            bodyFormdata.set("user[adress][title]", this.new_user.adress.title);
            bodyFormdata.set("user[adress][longitude]", this.new_user.adress.longitude);
            bodyFormdata.set("user[adress][latitude]", this.new_user.adress.latitude);


          }else{
            bodyFormdata.set("user_id", this.user.id);
          }

          bodyFormdata.set("address_id", this.address_id);
          bodyFormdata.set("order_status_id", this.order_status_id);
          bodyFormdata.set(
            "delivery_date",
            this.formatDate(this.delivery_date)
          );
          bodyFormdata.set("delivery_price", this.delivery_price);
          bodyFormdata.set("payment_method_id", this.payment_method_id);
          bodyFormdata.set("delivery_id", this.delivery_id);
          if (this.coupon.code)
            bodyFormdata.set("coupon_code", this.coupon.code);
          for (var i = 0; i < this.order_item_list.length; i++) {
            bodyFormdata.set(
              "products[" + this.order_item_list[i].id + "]",
              this.order_item_list[i].id
            );
            bodyFormdata.set(
              "products[" + this.order_item_list[i].id + "][quantity_id]",
              this.order_item_list[i].quantity_id
            );
            bodyFormdata.set(
              "products[" + this.order_item_list[i].id + "][quantity]",
              this.order_item_list[i].quantity
            );
          }
          this.createNewOrder(bodyFormdata)
            .then((response) => {
              this.successDialog(response.data.message);
              this.$router.go(-1);
            })
            .catch((error) => {
              let errorMessage = error.response.data.errors
                ? error.response.data.errors
                : error.message;
              this.errorDialog(errorMessage);
            });
        }
      });
    },
  },
  created() {
    this.fetchUnpaginated();
    this.productsSKU();
    this.fetchCoupons();
    this.fetchReps();
    this.fetchCities();
  },
};
</script>

<style>
.vs-divider--text {
  font-size: 18.48px;
}
.vx-col {
  margin-bottom: 10px;
}
</style>
