<template>
  <div class="vx-col w-full mb-5">
    <vs-table :data="order_item_list" v-if="order_item_list.length">

      <template slot="thead">
        <vs-th>SKU المنتج</vs-th>
        <vs-th>إسم المنتج</vs-th>
        <vs-th>اللون</vs-th>
        <vs-th>الحجم</vs-th>

        <vs-th>السعر الحالي</vs-th>
        <vs-th>سعر التخفيض الحالي</vs-th>
        
        <vs-th>سعر الشراء</vs-th>

        <vs-th>الكمية المحددة</vs-th>
        <vs-th>مجموع المطلوب</vs-th>
        <vs-th>العمليات</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <td>{{ tr.sku }}</td>
          <td>{{ tr.title }}</td>
          <td>{{ tr.color }}</td>
          <td>{{ tr.size }}</td>
          <td>{{ tr.price }}</td>
          <td>{{ tr.new_price?  tr.new_price : "لا يوجد"  }}</td>

          <td>{{ tr.total_amount ? tr.buy_price :"لايوجد"}}</td>

          <td><vs-input type="number" v-model="tr.quantity" 
          name="size_quantity"
          v-validate="{ max_value: tr.total_amount, required: true ,min_value:1}"
           class="w-1/2" ></vs-input>
            <span class="text-danger text-sm" v-show="errors.has('size_quantity')"
          >{{ errors.first("size_quantity") }}
        </span>
           </td>
          <td>{{ (tr.total_amount ? tr.total_amount : ( tr.quantity* (tr.new_price ? tr.new_price : tr.price ))) +" "+ (tr.currency.base=="LYD"?"د.ل":tr.currency.base)}}</td>

          <vs-td>
            <feather-icon
              icon="EyeIcon"
              svgClasses="w-5 h-5 hover:text-primary stroke-current"
              class="ml-2" title="مشاهدة المنتج"
              @click.stop="$emit('showItem', tr.id)"
            />
            <feather-icon
              icon="TrashIcon"
              svgClasses="w-5 h-5 hover:text-danger stroke-current"
              class="ml-2" title="حذف المنتج"
              @click.stop="$emit('deleteItem', indextr,tr.id)"
            />
          </vs-td>

        </vs-tr>
      </template>

    </vs-table>
    
  </div>
</template>

<script>
export default {
  props: {
    order_item_list: {
      type: Array,
      required: true,
    },
  },
  mounted(){  }
};
</script>

<style>
</style>
